@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@fontsource/inter/index.css";






body {
  font-family: 'Inter', sans-serif;
  background-color: var(--primary-bg);
  margin: 0;
  padding: 0;
  color: #000000; /* A default text color for contrast */
  
}

html {
  scroll-behavior: smooth;
}
